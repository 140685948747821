import React from 'react';

import './style.scss';

export default function LoadingSpinner() {
  return (
    <div className="spinner-wrapper">
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  );
}
