import axios from 'axios';

async function getToken(identity) {
  const currentUser = identity.user;
  if (!currentUser) {
    return '';
  }
  // fetchs new JWT token only if expired
  await currentUser.jwt();
  return currentUser.token.access_token;
}

const init = (identity) => {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(
    async (config) => {
      const token = await getToken(identity);
      if (token) {
        config.headers = {
          Authorization: token ? `Bearer ${token}` : '',
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

function authRequest(identity) {
  const instance = init(identity);

  return {
    getProjectsByUserId: (userId) =>
      instance
        .get(
          `${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/user/netlify/${userId}`
        )
        .then((res) => res.data)
        .catch((error) => console.error('Error: ', error.message)),
    deleteProjectById: (id) => {
      return instance
        .delete(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/${id}`)
        .then((res) => res.data)
        .catch((error) => console.error('Error: ', error.message));
    },
    getProjectById: (id) => {
      return instance
        .get(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/${id}`)
        .then((res) => res.data)
        .catch((error) => console.error('Error: ', error.message));
    },
    editProjectById: (id, data) => {
      return instance
        .put(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/${id}`, {
          data,
        })
        .then((res) => res.data)
        .catch((error) => console.error('Error: ', error.message));
    },
    createStripeCheckoutSession: (email, id) => {
      return instance
        .post(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/checkout`, {
          email,
          netlifyId: id,
        })
        .then((res) => res.data)
        .catch((error) => console.error('Error: ', error.message));
    },
  };
}

export default authRequest;
