const listBasic = (primaryColor = '0,0,0', secondaryColor = '255,255,255') => {
  return {
    showLabels: false,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'flex-start',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: '0,0,0',
      },
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
    },
    image: {
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: primaryColor,
      textColor: secondaryColor,
      borderRadius: {
        topLeft: 3,
        topRight: 3,
        bottomRight: 3,
        bottomLeft: 3,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        isBold: true,
      },
    },
  };
};
export default listBasic;
