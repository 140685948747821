// @flow

import './style.scss';

import * as React from 'react';
import type { Node } from 'react';

import { useState, useEffect } from 'react';

import SVGIcon from 'components/SVGIcon';

import { Link } from 'react-router-dom';

type Props = {
  href?: string,
  type?: 'button' | 'submit',
  children: Node,
  to?: string,
  iconFill?: 'white' | 'primary' | 'black' | 'secondary',
  iconFillHover?: 'white' | 'primary' | 'black' | 'secondary',
  secondary?: boolean,
  isRounded?: boolean,
  isTargetBlank?: boolean,
  isText?: boolean,
  isWhite?: boolean,
  padding?: 's' | 'm' | 'l',
  disabled?: boolean,
  onClick?: () => void,
};

/**
 * @function Button
 * @param {Object} props
 * @param {Node} [props.children]
 * @param {boolean} props.secondary
 * @param {'white' | 'primary' | 'black'} props.iconFill
 * @param {'white' | 'primary' | 'black'} props.iconFillHover
 * @param {boolean} props.isText
 * @param {boolean} props.isTargetBlank
 * @param {boolean} props.isWhite
 * @param {boolean} props.disabled
 * @param {'s' | 'm' | 'l'} props.padding
 * @param {string | Node} props.label
 * @param {string} props.to
 * @param {void} props.onClick
 * @returns {Node}
 * @exports Button
 */

export default function Button(props: Props): Node {
  const {
    href,
    children,
    secondary,
    disabled,
    onClick,
    isRounded,
    isText,
    isWhite,
    iconFill,
    iconFillHover,
    isTargetBlank,
    padding,
    to,
    type,
  } = props;
  const buttonClass = secondary
    ? 'Button__button--secondary'
    : 'Button__button';
  const roundedClass = isRounded ? 'Button__button--rounded' : '';
  const textClass = isText ? 'Button__button--text' : '';
  const whiteClass = isWhite ? 'Button__button--white' : '';
  let paddingClass;
  const [fill, setFill] = useState('');

  switch (padding) {
    case 's':
      paddingClass = 'Button__button--padding--s';
      break;
    case 'm':
      paddingClass = 'Button__button--padding--m';
      break;
    case 'l':
      paddingClass = 'Button__button--padding--l';
      break;
    default:
      paddingClass = '';
  }

  function settingFill(iFill) {
    switch (iFill) {
      case 'primary':
        setFill('#ee6c4d');
        break;
      case 'white':
        setFill('#fff');
        break;
      case 'black':
        setFill('#000');
        break;
      case 'secondary':
        setFill('#293241');
        break;
      default:
        setFill('#ee6c4d');
    }
  }

  useEffect(() => {
    settingFill(iconFill);
  }, []);

  let elementToRender = null;
  if (href) {
    elementToRender = (
      <a
        href={href}
        target={isTargetBlank ? '_blank' : '_self'}
        rel={isTargetBlank && 'noopener noreferrer'}
        className={`${buttonClass} ${roundedClass} ${textClass} ${whiteClass} ${paddingClass}`}
      >
        <>
          {children}
          {isTargetBlank && (
            <SVGIcon name="external-link" size={10} fill={fill} />
          )}
        </>
      </a>
    );
  } else if (to) {
    elementToRender = (
      <Link
        to={to}
        onClick={onClick}
        className={`${buttonClass} ${roundedClass} ${textClass} ${whiteClass} ${paddingClass}`}
      >
        {children}
      </Link>
    );
  } else {
    elementToRender = (
      <button
        type={type}
        className={`${buttonClass} ${roundedClass} ${textClass} ${whiteClass} ${paddingClass}`}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  let finalElement = null;

  if (isTargetBlank) {
    finalElement = (
      <div
        style={{ display: 'inline' }}
        onMouseEnter={() => settingFill(iconFillHover)}
        onMouseLeave={() => settingFill(iconFill)}
      >
        {elementToRender}
      </div>
    );
  } else {
    finalElement = <>{elementToRender}</>;
  }

  return <>{finalElement}</>;
}

Button.defaultProps = {
  href: '',
  to: '',
  type: 'button',
  iconFill: 'primary',
  iconFillHover: 'secondary',
  secondary: false,
  disabled: false,
  isRounded: false,
  isTargetBlank: false,
  padding: 'm',
  isText: false,
  isWhite: false,
  onClick: () => {},
};
