import React, { useState, useEffect } from 'react';

import { getGridsBySheetId } from 'api';

import Radio from 'components/Radio';
import Button from 'components/Button';
import Input from 'components/Input';
import Instructions from 'components/Instructions';

import PropTypes from 'prop-types';

import SheetsLogo from 'assets/images/sheets-logo.png';
import AirtableLogo from 'assets/images/airtable-logo.png';

import './style.scss';

export default function ConfigurationForm({
  baseIdPassed,
  apiKeyPassed,
  tableNamePassed,
  currentIntegrationPassed,
  getConfigFormData,
  next,
}) {
  // projects info provided by user
  const [configString, setConfigString] = useState('');
  const [currentIntegration, setCurrentIntegration] = useState(
    currentIntegrationPassed || 'google-sheets'
  );
  const [tableName, setTableName] = useState(tableNamePassed || 0);

  // generated credentials
  const [apiKey, setApiKey] = useState(apiKeyPassed || '');
  const [baseId, setBaseId] = useState(baseIdPassed || '');
  const [isCredentialsValid, setIsCredentialsValid] = useState(null);

  const [sheetsGridsList, setSheetsGridsList] = useState(null);

  useEffect(() => {
    if (configString) {
      const baseIdExtracted = configString.split('/');
      if (currentIntegration === 'airtable') {
        const regexForKey = /Bearer(.*)"/;
        const apiKeyExtracted = configString.match(regexForKey);
        if (apiKeyExtracted && baseIdExtracted) {
          setIsCredentialsValid(true);
          setApiKey(apiKeyExtracted[1].replace(/\s+/g, ''));
          setBaseId(baseIdExtracted[4]);
        } else {
          setIsCredentialsValid(false);
        }
      } else if (currentIntegration === 'google-sheets') {
        if (baseIdExtracted && baseIdExtracted[5]) {
          setIsCredentialsValid(true);
          setBaseId(baseIdExtracted[5]);
        } else {
          setIsCredentialsValid(false);
        }
      }
    }
  }, [configString, currentIntegration]);

  useEffect(() => {
    async function getData() {
      const grids = await getGridsBySheetId(baseId);
      setSheetsGridsList(grids);
      if (!tableNamePassed) {
        setTableName(grids[0].sheetId);
      }
    }
    if (currentIntegration === 'google-sheets' && isCredentialsValid) {
      getData();
    }
  }, [isCredentialsValid, baseId, currentIntegration]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.analytics.track('Create new project', { step: 1 });
    }
  }, []);

  useEffect(() => {
    if (baseId || apiKey) {
      if (currentIntegration === 'airtable') {
        if (apiKey && apiKey.length === 17 && baseId && baseId.length === 17) {
          setIsCredentialsValid(true);
        } else {
          setIsCredentialsValid(false);
        }
      } else if (baseId && baseId?.length === 44) {
        setIsCredentialsValid(true);
      } else {
        setIsCredentialsValid(false);
      }
    }
  }, [baseId, apiKey, currentIntegration]);

  function restConfigString() {
    setConfigString('');
    setIsCredentialsValid(null);
    setApiKey('');
    setBaseId('');
    setTableName(0);
  }

  function changeIntegration(name) {
    restConfigString();
    setCurrentIntegration(name);
  }

  const configFormSubmit = (e) => {
    e.preventDefault();
    next();
    const data = {
      apiKey,
      baseId,
      currentIntegration,
      tableName,
    };
    getConfigFormData(data);
  };

  let airtableConfigElement = null;
  let sheetsConfigElement = null;

  if (isCredentialsValid === null) {
    sheetsConfigElement = (
      <Input
        isRequired
        name="sheetId"
        label="Shared URL"
        placeholder="eg. https://docs.google.com/spreadsheets/d/1HXBzDNo3JfmIV3lHnbSoEeAVZsmXoHy4AU62RBvTMPs/edit#gid=0"
        value={baseId}
        onPaste={({ clipboardData }) => {
          setConfigString(clipboardData.getData('Text'));
        }}
      />
    );
  } else if (isCredentialsValid === true) {
    sheetsConfigElement = (
      <div className="AirtableConfigElement AirtableConfigElement--correct">
        <span role="img" aria-label="" className="AirtableConfigElement__emoji">
          😊
        </span>
        <span>Base ID: {baseId.substring(0, 12)}...</span>
        <Button isText onClick={restConfigString}>
          Edit
        </Button>
      </div>
    );
  } else {
    sheetsConfigElement = (
      <div className="AirtableConfigElement AirtableConfigElement--wrong">
        <span role="img" aria-label="" className="AirtableConfigElement__emoji">
          😟
        </span>
        <span>Something is wrong with what you pasted</span>
        <Button isText onClick={restConfigString}>
          Edit
        </Button>
      </div>
    );
  }

  if (isCredentialsValid === null) {
    airtableConfigElement = (
      <Input
        isRequired
        name="configString"
        tag="textarea"
        label="Configuration string"
        maxLength={125}
        placeholder="eg. $ curl https://api.airtable.com/v0/app88YHbjTa/My%20Base \
                -H 'Authorization: Bearer keyTxyiabssau"
        value={configString}
        onPaste={({ clipboardData }) =>
          setConfigString(clipboardData.getData('Text'))
        }
      />
    );
  } else if (isCredentialsValid === true) {
    airtableConfigElement = (
      <div className="AirtableConfigElement AirtableConfigElement--correct">
        <span role="img" aria-label="" className="AirtableConfigElement__emoji">
          😊
        </span>
        <span>API key: {apiKey.substring(0, 5)}...</span>
        <span>Base ID: {baseId}</span>
        <Button isText onClick={restConfigString}>
          Edit
        </Button>
      </div>
    );
  } else {
    airtableConfigElement = (
      <div className="AirtableConfigElement AirtableConfigElement--wrong">
        <span role="img" aria-label="" className="AirtableConfigElement__emoji">
          😟
        </span>
        <span>Something is wrong with what you pasted</span>
        <Button isText onClick={restConfigString}>
          Edit
        </Button>
      </div>
    );
  }

  return (
    <form className="ConfigurationForm" onSubmit={configFormSubmit}>
      <div>
        <div className="ConfigurationForm__IntegrationSelect">
          <Radio
            id="airtable"
            checked={currentIntegration === 'airtable'}
            onChange={() => changeIntegration('airtable')}
          >
            <img src={AirtableLogo} height="40px" alt="" />
            Airtable
          </Radio>
          <Radio
            id="google-sheets"
            checked={currentIntegration === 'google-sheets'}
            onChange={() => changeIntegration('google-sheets')}
          >
            <img src={SheetsLogo} height="40px" alt="" />
            Google Sheets
          </Radio>
        </div>
        {currentIntegration === 'airtable' ? (
          <Instructions integrationName="airtable" />
        ) : (
          <Instructions integrationName="google-sheets" />
        )}
        {currentIntegration === 'airtable' ? (
          <>
            <div>{airtableConfigElement}</div>
            <>
              <Input
                name="airtableTableName"
                label="Table name"
                type="text"
                value={tableName}
                onChange={({ target: { value } }) => setTableName(value)}
              />
            </>
          </>
        ) : (
          <>
            <div>{sheetsConfigElement}</div>
            {isCredentialsValid === true && sheetsGridsList && (
              <>
                <Input
                  name="sheetSelect"
                  tag="select"
                  label="Choose sheet"
                  value={tableName}
                  onChange={({ target: { value } }) => setTableName(value)}
                >
                  {sheetsGridsList.map((el) => (
                    <option value={el.sheetId} key={el.sheetId}>
                      {el.title}
                    </option>
                  ))}
                </Input>
              </>
            )}
          </>
        )}
      </div>
      <Button type="submit" disabled={!isCredentialsValid}>
        Next step
      </Button>
    </form>
  );
}
ConfigurationForm.propTypes = {
  getConfigFormData: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  baseIdPassed: PropTypes.string,
  apiKeyPassed: PropTypes.string,
  tableNamePassed: PropTypes.string,
  currentIntegrationPassed: PropTypes.string,
};
ConfigurationForm.defaultProps = {
  baseIdPassed: '',
  apiKeyPassed: '',
  tableNamePassed: '',
  currentIntegrationPassed: '',
};
