const templatesStyles = {
  'store-1': {
    showLabels: false,
    cards: {
      columns: 2,
    },
    singleElement: {
      direction: 'row',
      textAlign: 'left',
      justifyContent: 'center',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: '0,0,0',
      },
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
    },
    image: {
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '245,203,92',
      textColor: '36,36,35',
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        isBold: true,
      },
    },
  },
  'menu-1': {
    showLabels: false,
    cards: {
      columns: 2,
    },
    singleElement: {
      direction: 'row',
      textAlign: 'left',
      justifyContent: 'space-between',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 1,
        left: 0,
        right: 0,
        color: '245,245,245',
      },
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '36,62,54',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        isBold: true,
      },
    },
  },
  'our-team': {
    showLabels: false,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'space-between',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: '245,245,245',
      },
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
    },
    image: {
      borderRadius: {
        topLeft: 30,
        topRight: 30,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '50,67,118',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 20,
        topRight: 20,
        bottomRight: 20,
        bottomLeft: 20,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        isBold: true,
      },
    },
  },
  'list-basic': {
    showLabels: true,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'space-between',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 1,
        left: 0,
        right: 0,
        color: '215,215,215',
      },
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
    },
    image: {
      borderRadius: {
        topLeft: 50,
        topRight: 50,
        bottomRight: 50,
        bottomLeft: 50,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '50,67,118',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        isBold: true,
      },
    },
  },
  'cards-basic': {
    showLabels: true,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'flex-start',
      boxShadow: {
        x: 2,
        y: 2,
        blur: 10,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 1,
        left: 0,
        right: 0,
        color: '215,215,215',
      },
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
    },
    image: {
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '50,67,118',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        isBold: true,
      },
    },
  },
  'table-basic': {
    showLabels: true,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'flex-start',
      boxShadow: {
        x: 2,
        y: 2,
        blur: 10,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 1,
        left: 0,
        right: 0,
        color: '215,215,215',
      },
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
    },
    image: {
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '50,67,118',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '50,67,118',
        textColor: '255,255,255',
        isBold: true,
      },
    },
  },
  events: {
    showLabels: true,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'flex-start',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 1,
        bottom: 1,
        left: 1,
        right: 1,
        color: '5,41,158',
      },
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '5,41,158',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '50,67,118',
        textColor: '255,255,255',
        isBold: true,
      },
    },
  },
  counter: {
    showLabels: false,
    displayReversed: true,
    display: 1,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'flex-start',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: '5,41,158',
      },
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
    },
    button: {
      backgroundColor: '5,41,158',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '50,67,118',
        textColor: '255,255,255',
        isBold: true,
      },
    },
  },
  'top-5-restaurants-1': {
    showLabels: false,
    displayReversed: false,
    display: 5,
    cards: {
      columns: 3,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'space-between',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: '5,41,158',
      },
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 0,
        bottomLeft: 0,
      },
      boxShadow: {
        x: 2,
        y: 2,
        blur: 10,
        color: '251,86,7',
      },
    },
    button: {
      backgroundColor: '251,86,7',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '50,67,118',
        textColor: '255,255,255',
        isBold: true,
      },
    },
  },
  'catalog-1': {
    showLabels: true,
    singleElement: {
      direction: 'column',
      textAlign: 'left',
      justifyContent: 'flex-start',
      boxShadow: {
        x: 2,
        y: 2,
        blur: 10,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 1,
        left: 0,
        right: 0,
        color: '215,215,215',
      },
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomRight: 5,
        bottomLeft: 5,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '251,86,7',
      },
    },
    button: {
      backgroundColor: '158,246,246',
      textColor: '10,10,10',
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '158,246,246',
        textColor: '10,10,10',
        isBold: true,
      },
    },
  },
  'job-board': {
    showLabels: true,
    displayReversed: true,
    cards: {
      columns: 4,
    },
    singleElement: {
      direction: 'column',
      textAlign: 'center',
      justifyContent: 'space-between',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: '5,41,158',
      },
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '251,86,7',
      },
    },
    button: {
      backgroundColor: '158,246,246',
      textColor: '10,10,10',
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '158,246,246',
        textColor: '10,10,10',
        isBold: true,
      },
    },
  },
  'curated-list': {
    showLabels: true,
    displayReversed: false,
    cards: {
      columns: 1,
    },
    singleElement: {
      direction: 'row',
      textAlign: 'left',
      justifyContent: 'space-between',
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '0,0,0',
      },
      border: {
        top: 0,
        bottom: 1,
        left: 0,
        right: 0,
        color: '106,153,78',
      },
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },
    },
    image: {
      borderRadius: {
        topLeft: 2,
        topRight: 2,
        bottomRight: 2,
        bottomLeft: 2,
      },
      boxShadow: {
        x: 0,
        y: 0,
        blur: 0,
        color: '251,86,7',
      },
    },
    button: {
      backgroundColor: '106,153,78',
      textColor: '255,255,255',
      borderRadius: {
        topLeft: 10,
        topRight: 10,
        bottomRight: 10,
        bottomLeft: 10,
      },
      isBold: true,
    },
    table: {
      align: 'left',
      header: {
        backgroundColor: '158,246,246',
        textColor: '10,10,10',
        isBold: true,
      },
    },
  },
};
export default templatesStyles;
