import TemplatesGallery from 'components/TemplatesGallery';
import Title from 'components/Title';
import Header from 'components/Header';
import React from 'react';
import './style.scss';

export default function TemplatesGalleryView() {
  return (
    <main className="TemplatesGalleryView">
      <Header isCenter>
        <Title tag="h1" fontSize="l" isWhite isDecoration={false}>
          Available templates
        </Title>
      </Header>
      <div className="TemplatesGalleryView__content">
        <TemplatesGallery />
      </div>
    </main>
  );
}
