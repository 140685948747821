const types = {
  'store-1': 'cards',
  'menu-1': 'list',
  'our-team': 'cards',
  'list-basic': 'list',
  'cards-basic': 'cards',
  'table-basic': 'table',
  events: 'cards',
  counter: 'inline',
  'top-5-restaurants-1': 'list',
  'catalog-1': 'table',
  'job-board': 'cards',
  'curated-list': 'cards',
};
export default types;
