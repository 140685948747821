import React from 'react';

import PropTypes from 'prop-types';

import Title from 'components/Title';
import Input from 'components/Input';
import ColorInput from 'components/ColorInput';

import './style.scss';

export default function GroupOptions({ name, items }) {
  return (
    <div className="GroupOptions">
      <Title tag="h4" fontSize="xs" isDecoration={false}>
        {name}
      </Title>
      <div
        className="GroupOptions__options"
        style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
      >
        {items.map((item) => (
          <>
            {item.type === 'color' ? (
              <ColorInput
                showInRows
                label={item.label}
                name={item.label}
                value={item.value}
                onChange={item.onChange}
              />
            ) : (
              <Input
                key={item.id}
                isRequired
                tag="input"
                min={item.min}
                max={item.max}
                type={item.type}
                label={item.label}
                name={item.label}
                value={item.value}
                onChange={item.onChange}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
}

GroupOptions.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line
  items: PropTypes.array.isRequired,
};
