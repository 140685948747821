import Title from 'components/Title';
import Button from 'components/Button';
import React from 'react';
import './style.scss';

import propTypes from 'prop-types';

export default function Instructions({ integrationName }) {
  return (
    <div className="Instructions">
      <Title fontSize="s" tag="span" isDecoration={false}>
        Instructions
      </Title>
      {integrationName === 'airtable' ? (
        <div>
          <ol>
            <li>
              Go to
              <Button
                href="https://api.airtable.com"
                isTargetBlank
                isText
                padding="s"
              >
                api.airtable.com
              </Button>{' '}
              log in and select your base.
              <a
                href="/explainer-ss/airtable/01.png"
                className="secondary-link"
                target="_blank"
                rel="norefererr noopener"
              >
                View screenshot
              </a>
            </li>
            <li>
              Select &quot;show API key&quot; in a top right corner.
              <a
                href="/explainer-ss/airtable/02.png"
                className="secondary-link"
                target="_blank"
                rel="norefererr noopener"
              >
                View screenshot
              </a>
            </li>
            <li>
              Scroll down the site a little bit to the
              &quot;AUTHENTICATION&quot; section, copy the &quot;EXAMPLE USING
              BEARER TOKEN (RECOMMENDED)&quot; snippet, and paste it down below.
              <a
                href="/explainer-ss/airtable/03.png"
                className="secondary-link"
                target="_blank"
                rel="norefererr noopener"
              >
                View screenshot
              </a>
            </li>
          </ol>
        </div>
      ) : (
        <div>
          <ol>
            <li>Go to your Google Sheet.</li>
            <li>
              Select &quot;Share&quot; in the top right corner
              <a
                href="/explainer-ss/google-sheets/01.png"
                className="secondary-link"
                target="_blank"
                rel="norefererr noopener"
              >
                View screenshot
              </a>
            </li>
            <li>
              When you can see a modal click &quot;Get link&quot; and set
              permissions to &quot;Anyone with the link&quot;
              <a
                href="/explainer-ss/google-sheets/02.png"
                className="secondary-link"
                target="_blank"
                rel="norefererr noopener"
              >
                View screenshot
              </a>
            </li>
            <li>
              Now you can copy the URL form the modal and paste it down below.
              <a
                href="/explainer-ss/google-sheets/03.png"
                className="secondary-link"
                target="_blank"
                rel="norefererr noopener"
              >
                View screenshot
              </a>
            </li>
          </ol>
        </div>
      )}
    </div>
  );
}
Instructions.propTypes = {
  integrationName: propTypes.string.isRequired,
};
