// @flow

import React, { useEffect } from 'react';
import type { Node } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import { createStripeCheckoutSession } from 'api';

import './style.scss';

import IdentityModal, {
  useIdentityContext,
} from 'react-netlify-identity-widget';
import { useHistory } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

/**
 * @function Home
 * @param {Object} props
 * @returns {Node}
 * @exports Home
 */

const Home = (): Node => {
  const identity = useIdentityContext();
  const history = useHistory();

  async function processStripeCheckout({ email, id }) {
    const stripe = await stripePromise;
    const response = await createStripeCheckoutSession(email, id);
    const result = await stripe.redirectToCheckout({
      sessionId: response.id,
    });

    if (result.error) {
      console.log(result.error.message);
    }
  }

  useEffect(() => {
    if (identity.user) {
      history.push('/dashboard');
    }
  }, [identity, history]);

  return (
    <main className="Home">
      <div>
        <IdentityModal
          showDialog
          onSignup={(user) => processStripeCheckout(user)}
        />
      </div>
    </main>
  );
};

export default Home;
