import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CopyBlock, dracula } from 'react-code-blocks';

import { getProjectsById } from 'api';

import airtableLogo from 'assets/images/airtable-logo.png';
import sheetsLogo from 'assets/images/sheets-logo.png';
import wpLogo from 'assets/images/wp-logo.png';

import LoadingSpinner from 'components/LoadingSpinner';
import Title from 'components/Title';
import Header from 'components/Header';
import InfoBox from 'components/InfoBox';
import Button from 'components/Button';

import usePrevious from 'hooks/usePrevious';

import './style.scss';
import Radio from 'components/Radio';

import {
  EmailShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterShareButton,
  LinkedinIcon,
} from 'react-share';

export default function SingleProject() {
  const { projectId } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [snippetCode, setSnippetCode] = useState(null);
  const [snippetType, setSnippetType] = useState('html');

  const prevProjectId = usePrevious(projectId);

  function generateDevEmail(snippetCodeParam) {
    return `mailto:DEVELOPER@DOMAIN.com?subject=Set%20up%20EmbedTables%20on%20YOUR_WEBSITE_NAME&body=Hey!%20Here's%20a%20code%20snippet%20from%20embedtables.com%20to%20easily%20embed%20data%20from%20Google%20Sheets%20or%20Airtable%20on%20a%20website.%0D%0A%0D%0ACode%20snippet%3A%0D%0A%0D%0A${snippetCodeParam}%0D%0A%0D%0AYou%20can%20read%20more%20about%20how%20to%20set%20it%20up%20here%20-%20https%3A%2F%2Fapp.embedtables.com%2Ftutorials%20or%20contact%20directly%20Dominik%20-%20dominik%40embedtables.com`;
  }

  /* todo: refactoring */

  React.useEffect(() => {
    async function getProjectInfo() {
      const data = await getProjectsById(projectId);
      setProjectData(data);
    }
    if (projectId && prevProjectId !== projectId) {
      getProjectInfo();
      let snippet = null;
      if (snippetType === 'html')
        snippet = `<div data-embedtableid="${projectId}"></div>\n<script async src="https://scripts.embedtables.com/script.js"></script>`;
      else snippet = `[embedtable id="${projectId}"]`;
      setSnippetCode(snippet);
    }
  }, [projectId, snippetType, prevProjectId]);

  React.useEffect(() => {
    let snippet = null;
    if (snippetType === 'html') {
      snippet = `<div data-embedtableid="${projectId}"></div>\n<script async src="https://scripts.embedtables.com/script.js"></script>`;
    } else if (snippetType === 'iframe') {
      snippet = `<iframe
    width="100%"
    height="1024px"
    border="0"
    frameborder="0"
    src="https://show.embedtables.com/${projectId}"
    sandbox="allow-scripts"
  ></iframe>`;
    } else {
      snippet = `[embedtable id="${projectId}"]`;
    }
    setSnippetCode(snippet);
  }, [snippetType, projectId]);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.analytics.page('Single project');
    }
  }, []);

  const shareTitle = 'Here is a link to my project made with EmbedTables';

  return (
    <main className="SingleProject">
      {projectData ? (
        <div>
          <Header isCenter bgColor={projectData.primaryColor}>
            <Title tag="h2" fontSize="l" isDecoration={false} isWhite>
              {projectData.projectName}
            </Title>
          </Header>
          <section className="SingleProject__content">
            <div className="SingleProject__title-wrapper">
              <Title tag="h2" fontSize="m">
                Integrate with a website
              </Title>
              <a
                className="primary-link"
                href={`mailto:someone@yoursite.com?subject=Set%20up%20EmbedTables%20on%20YOUR_WEBSITE&body=${generateDevEmail(
                  snippetCode
                )}`}
              >
                Send to your developer
              </a>
            </div>

            <div className="SingleProject__snippetType">
              <Radio
                checked={snippetType === 'html'}
                onChange={() => setSnippetType('html')}
              >
                HTML/JS
              </Radio>
              <Radio
                checked={snippetType === 'wp'}
                onChange={() => setSnippetType('wp')}
              >
                <img src={wpLogo} width="30px" alt="" />
                WordPress
              </Radio>
              <Radio
                checked={snippetType === 'iframe'}
                onChange={() => setSnippetType('iframe')}
              >
                Iframe
              </Radio>
            </div>
            <CopyBlock
              theme={dracula}
              text={snippetCode}
              wrapLines
              language="markup"
              codeBlock
            />
            {snippetType === 'wp' && (
              <div className="SingleProject__help">
                <InfoBox title="Install WordPress plugin for EmbedTables!">
                  <p>
                    Now you can easily integrate EmbedTables with a WordPress
                    website using our new plugin.
                  </p>
                  <Button
                    href="https://wordpress.org/plugins/embedtables-embed-google-sheets-on-a-website/"
                    isTargetBlank
                    iconFill="white"
                    padding="s"
                  >
                    Get it!
                  </Button>
                </InfoBox>
              </div>
            )}
            {snippetType === 'iframe' && (
              <div className="SingleProject__help">
                <InfoBox title="Choose Iframe only if you can't use different options">
                  <p>
                    I recommend using HTML/JS and WordPress snippets as the
                    first choice for better SEO and responsive design results.
                  </p>
                </InfoBox>
              </div>
            )}
            <div className="SingleProject__help">
              <InfoBox title="Need some help?">
                {/* <Title tag="h4" fontSize="xs" isDecoration={false}>
                  Tutorials
                </Title>
                <div className="SingleProject__tuts">
                  <Link to="/" className="primary-link">
                    Integrate with Wix
                  </Link>
                  <Link to="/" className="primary-link">
                    Integrate with Wordpress
                  </Link>
                  <Link to="/" className="primary-link">
                    Integrate with Shopify
                  </Link>
                  <Link to="/" className="primary-link">
                    Integrate with HTML
                  </Link>
                </div> */}
                <div className="SingleProject__tuts">
                  <a
                    href="mailto:dominik@embedtables.com?subject=Help with configuration needed!"
                    className="primary-link"
                  >
                    Shoot me an email
                  </a>
                  <a
                    href="https://twitter.com/DominikIlnicki"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="primary-link"
                  >
                    DM me on Twitter
                  </a>
                </div>
              </InfoBox>
            </div>
            {projectId && (
              <div className="SingleProject__about">
                <div className="SingleProject__shareTitle">
                  <Title tag="h2" fontSize="m">
                    Preview URL
                  </Title>
                  <div>
                    <EmailShareButton
                      url={`https://show.embedtables.com/${projectId}`}
                      title={shareTitle}
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                  <div>
                    <TwitterShareButton
                      url={`https://show.embedtables.com/${projectId}`}
                      title={shareTitle}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                  <div>
                    <FacebookShareButton
                      url={`https://show.embedtables.com/${projectId}`}
                      quote={shareTitle}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </div>
                  <div>
                    <LinkedinShareButton
                      url={`https://show.embedtables.com/${projectId}`}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                </div>
                <div>
                  <Button
                    isText
                    isTargetBlank
                    padding="s"
                    href={`https://show.embedtables.com/${projectId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    show.embedtables.com/{projectId}
                  </Button>
                </div>
              </div>
            )}

            <div className="SingleProject__about">
              <Title tag="h2" fontSize="m">
                About project
              </Title>
              <div className="SingleProject__info">
                <div className="SingleProject__row">
                  <span>Integration</span>

                  {projectData.integrationName === 'airtable' ? (
                    <img
                      src={airtableLogo}
                      width="20px"
                      alt="Airtable integration"
                    />
                  ) : (
                    <img
                      src={sheetsLogo}
                      width="20px"
                      alt="Google Sheets integration"
                    />
                  )}
                </div>
                <div className="SingleProject__row">
                  <span>View type</span>
                  <span>{projectData.type}</span>
                </div>
                <div className="SingleProject__row">
                  <span>Columns names as labels</span>
                  <span>
                    {projectData.userStyles.showLabels ? 'Yes' : 'No'}
                  </span>
                </div>
                <div className="SingleProject__row">
                  <span>Rows in reversed order</span>
                  <span>
                    {projectData.userStyles.displayReversed ? 'Yes' : 'No'}
                  </span>
                </div>
                <div className="SingleProject__row">
                  <span>Hiden columns</span>
                  {projectData.columnsToHide?.length > 0 ? (
                    <div>
                      {projectData.columnsToHide.map((el) => (
                        <span>{el}</span>
                      ))}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </main>
  );
}
