// @flow

import * as React from 'react';
import type { Node } from 'react';

type Props = {
  fill: string,
  size: number,
  name: string,
};

export default function ExternalLinkIcon(props: Props): Node {
  const { fill, size, name } = props;

  const getViewBox = (iconName) => {
    switch (iconName) {
      case 'external-link':
        return '0 0 283.922 283.922';
      case 'more-info':
        return '0 0 512 512';
      case 'edit':
        return '0 0 24 24';
      case 'delete':
        return '0 0 24 24';
      default:
        return '0 0 512 512';
    }
  };
  const getPath = (iconName, iconFill) => {
    switch (iconName) {
      case 'external-link':
        return `
      <g>
      <path
        fill=${iconFill}
        d="M266.422,0h-97.625c-9.65,0-17.5,7.851-17.5,17.5c0,9.649,7.85,17.5,17.5,17.5h55.377l-92.375,92.374
              c-3.307,3.305-5.127,7.699-5.127,12.375c0,4.676,1.819,9.069,5.125,12.371c3.306,3.309,7.699,5.13,12.375,5.13
              c4.674,0,9.069-1.82,12.376-5.127l92.374-92.375v55.377c0,9.649,7.851,17.5,17.5,17.5c9.649,0,17.5-7.851,17.5-17.5V17.5
              C283.922,7.851,276.071,0,266.422,0z"
      />
      <path
        fill=${iconFill}
        d="M201.137,253.922H30V82.785h128.711l30-30H15c-8.284,0-15,6.716-15,15v201.137c0,8.284,6.716,15,15,15h201.137
              c8.284,0,15-6.716,15-15V95.211l-30,30V253.922z"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
      `;
      case 'more-info':
        return `<path
        fill=${iconFill}
        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
      />`;
      case 'edit':
        return `<path fill=${iconFill} d="M18 13.45l2-2.023v4.573h-2v-2.55zm-11-5.45h1.743l1.978-2h-3.721v2zm1.361 3.216l11.103-11.216 4.536 4.534-11.102 11.218-5.898 1.248 1.361-5.784zm1.306 3.176l2.23-.472 9.281-9.378-1.707-1.707-9.293 9.388-.511 2.169zm3.333 7.608v-2h-6v2h6zm-8-2h-3v-2h-2v4h5v-2zm13-2v2h-3v2h5v-4h-2zm-18-2h2v-4h-2v4zm2-6v-2h3v-2h-5v4h2z"/>`;
      case 'delete':
        return `<path fill=${iconFill} d="M21 6l-3 18h-12l-3-18h2.028l2.666 16h8.611l2.666-16h2.029zm-4.711-4c-.9 0-1.631-1.099-1.631-2h-5.316c0 .901-.73 2-1.631 2h-5.711v2h20v-2h-5.711z"/>`;
      default:
        return '';
    }
  };

  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="info-circle"
      className="svg-inline--fa fa-info-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={getViewBox(name)}
      width={size}
      style={{ marginLeft: '10px' }}
      dangerouslySetInnerHTML={{ __html: getPath(name, fill) }}
    />
  );
}
