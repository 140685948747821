// @flow

import * as React from 'react';
import type { Node } from 'react';

import './style.scss';

/**
 * @function Title
 * @param {Object} props
 * @param {string} props.tag
 * @param {Node} props.children
 * @param {boolean} props.isPrimary
 * @param {boolean} props.isMargin
 * @param {boolean} props.isWhite
 * @param {boolean} props.isBlack
 * @param {boolean} props.isDecoration
 * @param {string} props.fontSize
 * @returns {Node}
 * @exports Title
 */

type Props = {
  tag: string,
  children: Node,
  isPrimary?: boolean,
  isMargin?: boolean,
  isBlack?: boolean,
  isWhite?: boolean,
  isDecoration?: boolean,
  fontSize?: 'xs' | 's' | 'm' | 'l',
};

export default function Title(props: Props): Node {
  const {
    tag: Tag,
    children,
    isPrimary,
    isBlack,
    isWhite,
    isMargin,
    fontSize,
    isDecoration,
  } = props;
  let titleClass = 'Title';
  if (isPrimary) {
    titleClass += ' Title--primary';
  } else if (isBlack) {
    titleClass += ' Title--black';
  } else if (isWhite) {
    titleClass += ' Title--white';
  }
  if (isDecoration) {
    titleClass += ' Title--decoration';
  }
  let size = '1rem';
  switch (fontSize) {
    case 'xs':
      size = '1rem';
      break;
    case 's':
      size = '1.2rem';
      break;
    case 'm':
      size = '1.5rem';
      break;
    case 'l':
      size = '2.4rem';
      break;
    default:
      size = '1rem';
  }

  return (
    <Tag
      className={titleClass}
      style={{ fontSize: size, margin: !isMargin && '0' }}
    >
      {children}
    </Tag>
  );
}

Title.defaultProps = {
  isPrimary: false,
  isBlack: false,
  isWhite: false,
  isMargin: true,
  isDecoration: true,
  fontSize: '1.8rem',
};
