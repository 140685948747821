// @flow

import * as React from 'react';
import type { Node } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import logo from 'assets/logo.svg';

import { getGreeting } from 'utils';

import AppContext from 'context';

export default function HeaderMenu(): Node {
  const { loginDialogValue, userDataValue } = React.useContext(AppContext);
  const [, setLoginDialog] = loginDialogValue;
  const [userData] = userDataValue;

  return (
    <nav className="HeaderMenu">
      <Link to="/" className="HeaderMenu__logo">
        <img src={logo} alt="Go to homepage" />
        {userData && (
          <span className="HeaderMenu__greetings">
            {getGreeting()} {userData.fullName.replace(/ .*/, '')}
            <span role="img" aria-label="">
              👋
            </span>
          </span>
        )}
      </Link>
      <ul className="HeaderMenu__elements">
        <li>
          <Button
            href="https://billing.stripe.com/p/login/4gwdRMg0k5okcCc288"
            isText
            padding="s"
            isWhite
            isTargetBlank
            iconFill="white"
          >
            Billing
          </Button>
        </li>
        <li>
          <Button
            href="https://embedtables.com/examples"
            isText
            padding="s"
            isWhite
            isTargetBlank
            iconFill="white"
          >
            Examples
          </Button>
        </li>
        <li>
          <Button
            isText
            padding="s"
            isWhite
            onClick={() => setLoginDialog(true)}
          >
            Log out
          </Button>
        </li>
      </ul>
    </nav>
  );
}
