// @flow

import * as React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from 'views/Home';
import CreateProject from 'views/CreateProject';
import { IdentityContextProvider } from 'react-netlify-identity-widget';
import ContextProvider from 'context/ContextProvider';
import 'assets/scss/netlify-widget.scss';
import PrivateRoute from 'components/PrivateRoute';
import Dashboard from 'views/Dashboard';
import '@reach/tabs/styles.css';
import HeaderMenu from 'components/HeaderMenu';
import SingleProject from 'views/SingleProject';
import Tutorials from 'views/Tutorials';
import TemplatesGalleryView from 'views/TemplatesGalleryView';

const url = 'https://app-embedtables.netlify.app'; // supply the url of your Netlify site instance. VERY IMPORTANT. no point putting in env var since this is public anyway

const Root = () => {
  return (
    <IdentityContextProvider url={url}>
      <ContextProvider>
        <BrowserRouter>
          <div style={{ display: 'grid', gridTemplateRows: '70px 1fr' }}>
            <HeaderMenu />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/tutorials" exact component={Tutorials} />
              <PrivateRoute
                shouldBePaid
                path="/dashboard"
                exact
                component={Dashboard}
              />
              <PrivateRoute
                shouldBePaid
                path="/templates-gallery"
                exact
                component={TemplatesGalleryView}
              />
              <PrivateRoute path="/create" exact component={CreateProject} />
              <PrivateRoute
                path="/edit/:projectId"
                exact
                component={CreateProject}
              />
              <PrivateRoute
                path="/project/:projectId"
                exact
                component={SingleProject}
              />
              <Route path="/success" exact>
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
      </ContextProvider>
    </IdentityContextProvider>
  );
};

export default Root;
