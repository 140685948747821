/* eslint-disable */

import React from 'react';
import { TwitterPicker } from 'react-color';

import './style.scss';

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: this.props.value.split(',')[0],
      g: this.props.value.split(',')[1],
      b: this.props.value.split(',')[2],
      a: 1,
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });

    this.props.onChange(`${color.rgb.r},${color.rgb.g},${color.rgb.b}`);
  };

  render() {
    const bgColor = `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`;
    let wrapperStyles = null;
    if (this.props.showInRows) {
      wrapperStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      };
    }

    return (
      <div className="ColorInput" style={wrapperStyles}>
        <span>{this.props.label}</span>
        <div className="swatch" onClick={this.handleClick}>
          <div className="color" style={{ backgroundColor: bgColor }} />
        </div>
        {this.state.displayColorPicker ? (
          <div className="popover">
            <div className="cover" onClick={this.handleClose} />
            <TwitterPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
