const templateOptions = [
  {
    id: 1,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Basic list',
    slug: 'list-basic',
    primaryColor: '#D6F67E',
    secondaryColor: '#000',
    airtableUrl:
      'https://airtable.com/invite/l?inviteId=invae9v4eXOurcsdi&inviteToken=4df70f1a817b44cd3930905d62bc63dc7127a7e50b653a170a2c74d9fc30069e',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=1316979610',
  },

  {
    id: 3,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Basic cards',
    slug: 'cards-basic',
    primaryColor: '#3F6C51',
    secondaryColor: '#fff',
    category: 'calendars',
    airtableUrl:
      'https://airtable.com/invite/l?inviteId=invpQmMto9r6O96ki&inviteToken=f4b15975c7a17aaa31f0ffe337e79fb6739a5ffd8543b552ca038e160666e823',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=1477022430',
  },
  {
    id: 4,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Basic table',
    slug: 'table-basic',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=462684955',
  },
  {
    id: 6,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Menu',
    slug: 'menu-1',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=2049333634',
  },
  {
    id: 7,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Store',
    slug: 'store-1',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=901434823',
  },
  {
    id: 7,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Product catalog',
    slug: 'catalog-1',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=315464776',
  },
  {
    id: 8,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Curated list',
    slug: 'curated-list',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=2023643447',
  },
  {
    id: 9,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Top 5 restaurants',
    slug: 'top-5-restaurants-1',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=769637340',
  },
  // {
  //   id: 10,
  //   desc:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //   label: 'Best deals list',
  //   slug: 'best-deals',
  // },
  {
    id: 11,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Our team',
    slug: 'our-team',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=2046327659',
  },
  {
    id: 12,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Job board',
    slug: 'job-board',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=1261754855',
  },
  {
    id: 13,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Counter',
    slug: 'counter',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=676168819',
  },
  {
    id: 14,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    label: 'Events list',
    slug: 'events',
    sheetUrl:
      'https://docs.google.com/spreadsheets/d/182vHmkhIbmg5o4jqzFR5spTXR6XFynBDDF3VAE948Ys/edit#gid=1299850300',
  },
  // {
  //   id: 15,
  //   desc:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //   label: 'FAQ',
  //   slug: 'faq',
  // },
];
export default templateOptions;
