import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useIdentityContext } from 'react-netlify-identity-widget';
import { loadStripe } from '@stripe/stripe-js';

import PropTypes from 'prop-types';
import authRequest from 'api/authRequest';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const identity = useIdentityContext();

  const API = authRequest(identity);

  async function processStripeCheckout({ email, id }) {
    const stripe = await stripePromise;
    const response = await API.createStripeCheckoutSession(email, id);
    const result = await stripe.redirectToCheckout({
      sessionId: response.id,
    });

    if (result.error) {
      console.log(result.error.message);
    }
  }

  useEffect(() => {
    async function updateUser() {
      await identity.updateUser({});
      if (!identity.user.app_metadata.roles.includes('sub: pro')) {
        if (!identity.user.app_metadata.roles.includes('starter')) {
          processStripeCheckout(identity.user);
        }
      }
    }
    if (identity.user) {
      updateUser();
    }
  }, [identity]);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        identity.user ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default PrivateRoute;
