import Title from 'components/Title';
import Header from 'components/Header';
import React from 'react';

import InfoBox from 'components/InfoBox';

import './style.scss';

export default function Tutorials() {
  return (
    <main className="Tutorials">
      <Header isCenter>
        <Title tag="h1" fontSize="l" isDecoration={false} isWhite>
          Tutorials
        </Title>
      </Header>
      <div className="Tutorials__content">
        <Title tag="h2" fontSize="m">
          Ran into a problem?
        </Title>
        <p>
          You can shoot me an email at{' '}
          <a
            href="mailto:dominik@embedtables.com?subject=Help with configuration needed!"
            className="primary-link"
          >
            dominik@embedtables.com
          </a>{' '}
          and I&apos;ll help you with all the configuration details as fast as
          it is possible (remeber I&apos;m in GMT+1){' '}
          <span role="img" aria-hidden="true">
            😃
          </span>
        </p>
        <div>
          <Title tag="h2" fontSize="m">
            Configuration
          </Title>
          <div className="InfoWrapper" id="html">
            <InfoBox title="HTML">
              <ol>
                <li>
                  Put &lt;script async
                  src=&quot;https://scripts.embedtables.com/script.js&quot;&gt;&lt;/script&gt;
                  to the head section of your site.
                </li>
                <li>
                  Find a place you want to embed Google Sheet/Airtable data in
                  your HTML and simply paste there &lt;div
                  data-emebedtableid=&apos;PROJECT_ID&apos;&gt;&lt;/directives&gt
                  element with your project&apos;s ID.
                </li>
                <li>
                  That&apos;s it! If something doesn&apos;t work properly please
                  let me know - dominik@embedtables.com
                </li>
              </ol>
            </InfoBox>
          </div>
          <div className="InfoWrapper" id="wordpress">
            <InfoBox title="WordPress">
              <ol>
                <li>
                  Open &apos;Pages&apos; tab and select or create a page where
                  you want to put your data.
                </li>
                <li>Switch from visuall to the textual editor.</li>
                <li>
                  Put your snippet (script and div tag) where you want to see
                  the content.
                </li>
                <li>
                  That&apos;s it! If something doesn&apos;t work properly please
                  let me know - dominik@embedtables.com
                </li>
              </ol>
              <span className="disclaimer">
                This is a temporary configuration method. WordPress plugin will
                be published soon.
              </span>
            </InfoBox>
          </div>
        </div>
      </div>
    </main>
  );
}
