/* eslint-disable */

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import generate from 'project-name-generator';

import usePrevious from 'hooks/usePrevious';

import { getFieldsFromGoogleSheets, getFieldsFromAirtable } from 'api';

import { v4 as uuidv4 } from 'uuid';

import 'embedtables-element';
import LoadingSpinner from 'components/LoadingSpinner';
import Radio from 'components/Radio';

import useCallOnce from 'hooks/useCallOnce';
import { hexToRGB, rgbToHex } from 'utils';

import 'assets/scss/accordion.scss';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './style.scss';
import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/Button';
import ColorInput from 'components/ColorInput';
import GroupOptions from './GroupOptions';
import api from './fields';

export default function CustomizationForm({
  baseId,
  tableName,
  apiKey,
  currentIntegrationPassed,
  userStylesPassed,
  projectTypePassed,
  projectNamePassed,
  columnsToHidePassed,
  getCustomizationData,
  passCustomizationData,
  isEdit,
}) {
  const [projectFields, setProjectFields] = useState(null);
  const [projectFieldsCopy, setProjectFieldsCopy] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [type, setType] = useState(projectTypePassed || 'cards');
  const [currentIntegration] = useState(
    currentIntegrationPassed || 'google-sheets'
  );
  const [showDisplay, setShowDisplay] = useState(
    userStylesPassed?.display || false
  );

  const [projectName, setProjectName] = useState(
    projectNamePassed || generate({ words: 4 }).dashed
  );

  const [columnsToHide, setColumnsToHide] = useState(columnsToHidePassed || []);
  const [allColumns, setAllColumns] = useState([]);
  const [doHideColumns, setDoHideColumns] = useState(
    columnsToHidePassed.length > 0
  );

  const [userStyles, setUserStyles] = useState(
    userStylesPassed || {
      showLabels: true,
      display: null,
      displayReversed: false,
      cards: {
        columns: 3,
      },
      singleElement: {
        direction: 'column',
        textAlign: 'left',
        justifyContent: 'flex-start',
        boxShadow: {
          x: 2,
          y: 2,
          blur: 10,
          color: '0,0,0',
        },
        border: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          color: '0,0,0',
        },
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomRight: 5,
          bottomLeft: 5,
        },
      },
      image: {
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomRight: 0,
          bottomLeft: 0,
        },
        boxShadow: {
          x: 0,
          y: 0,
          blur: 0,
          color: '0,0,0',
        },
      },
      button: {
        backgroundColor: '100,10,20',
        textColor: '250,250,250',
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomRight: 5,
          bottomLeft: 5,
        },
        isBold: true,
      },
      table: {
        align: 'left',
        header: {
          backgroundColor: '200,10,100',
          textColor: '245,245,245',
          isBold: true,
        },
      },
    }
  );
  const prevColumnsToHide = usePrevious(columnsToHide);

  useEffect(() => {
    async function getData() {
      let data = null;
      if (currentIntegration === 'google-sheets') {
        data = await getFieldsFromGoogleSheets(baseId, tableName);
      } else if (currentIntegration === 'airtable') {
        data = await getFieldsFromAirtable(apiKey, baseId, tableName);
      }
      setProjectFields(data);
      setProjectFieldsCopy(data.fields);
      // setProjectFields(api);
      // setProjectFieldsCopy(api.fields);
      setLoaded(true);
    }
    getData();
  }, [baseId, tableName, apiKey, currentIntegration]);

  useEffect(() => {
    if (userStyles)
      getCustomizationData(userStyles, projectName, type, columnsToHide);
  }, [userStyles, projectName, type, columnsToHide]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.analytics.track('Create new project', { step: 2 });
    }
  }, []);
  useCallOnce(() => {
    if (projectFields) {
      setAllColumns(
        projectFields.fields[0].map((el) => ({
          id: uuidv4(),
          columnName: el.name,
        }))
      );
    }
  }, projectFields);

  useEffect(() => {
    if (!doHideColumns) setColumnsToHide([]);
  }, [doHideColumns]);

  useEffect(() => {
    if (
      columnsToHide &&
      projectFieldsCopy &&
      projectFields &&
      columnsToHide !== prevColumnsToHide
    ) {
      setProjectFields(() => ({
        fields: projectFieldsCopy.map((row) =>
          row.filter((el) => !columnsToHide.includes(el.name))
        ),
      }));
    }
  }, [columnsToHide, prevColumnsToHide, projectFields, projectFieldsCopy]);

  const passForm = (e) => {
    e.preventDefault();
    if (userStyles) passCustomizationData();
  };

  return (
    <>
      {loaded ? (
        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          preExpanded={['basicSettings']}
        >
          <div className="CustomizationForm">
            <form
              className="CustomizationForm__form"
              onSubmit={(e) => passForm(e)}
            >
              <AccordionItem uuid="basicSettings">
                <section>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Title fontSize="s" tag="h3" isDecoration={false}>
                        Basic settings
                      </Title>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Input
                      isRequired
                      name="projectName"
                      label="Project Name"
                      placeholder="eg. Product list"
                      value={projectName}
                      onChange={({ target: { value } }) =>
                        setProjectName(value)
                      }
                    />
                    <div className="CustomizationForm__type">
                      <Radio
                        checked={type === 'cards'}
                        onChange={() => setType('cards')}
                      >
                        Cards
                      </Radio>
                      <Radio
                        checked={type === 'list'}
                        onChange={() => setType('list')}
                      >
                        List
                      </Radio>
                      <Radio
                        checked={type === 'table'}
                        onChange={() => setType('table')}
                      >
                        Table
                      </Radio>
                      <Radio
                        checked={type === 'inline'}
                        onChange={() => setType('inline')}
                      >
                        Inline
                      </Radio>
                    </div>
                    <Input
                      tag="input"
                      type="checkbox"
                      label="Column names as labels"
                      name="showLabels"
                      checked={userStyles.showLabels}
                      onChange={({ target: { checked } }) =>
                        setUserStyles((prev) => ({
                          ...prev,
                          showLabels: checked,
                        }))
                      }
                    />
                    <Input
                      tag="input"
                      type="checkbox"
                      label="Rows in reversed order"
                      name="displayReversed"
                      checked={userStyles.displayReversed}
                      onChange={({ target: { checked } }) => {
                        setUserStyles((prev) => ({
                          ...prev,
                          displayReversed: checked,
                        }));
                      }}
                    />
                    <Input
                      tag="input"
                      type="checkbox"
                      label="Specific number of rows"
                      name="showDisplay"
                      checked={showDisplay}
                      onChange={({ target: { checked } }) => {
                        setShowDisplay(checked);
                        setUserStyles((prev) => ({
                          ...prev,
                          display: checked ? 3 : null,
                        }));
                      }}
                    />
                    {showDisplay && (
                      <div className="Dashboard__subsettings">
                        <Input
                          tag="input"
                          type="number"
                          label="How many rows should be shown?"
                          name="display"
                          min="1"
                          max="15"
                          value={userStyles.display}
                          onChange={({ target: { value } }) =>
                            setUserStyles((prev) => ({
                              ...prev,
                              display: value,
                            }))
                          }
                        />
                      </div>
                    )}

                    {currentIntegration === 'google-sheets' && allColumns ? (
                      <div>
                        <Input
                          type="checkbox"
                          label="Hide columns"
                          name="doHideColumns"
                          checked={doHideColumns}
                          onChange={({ target: { checked } }) =>
                            setDoHideColumns(checked)
                          }
                        />
                        {doHideColumns && (
                          <ul>
                            {allColumns.map(({ columnName, id }) => (
                              <li key={id}>
                                <Input
                                  type="checkbox"
                                  label={columnName}
                                  data-colname={columnName}
                                  name={`hide-${columnName}`}
                                  checked={columnsToHide.includes(columnName)}
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      setColumnsToHide((prev) =>
                                        prev.concat(target.dataset.colname)
                                      );
                                    } else {
                                      setColumnsToHide((prev) =>
                                        prev.filter(
                                          (el) => el !== target.dataset.colname
                                        )
                                      );
                                    }
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : null}
                  </AccordionItemPanel>
                </section>
              </AccordionItem>

              {type === 'table' && userStyles.showLabels && (
                <AccordionItem>
                  <section>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Title fontSize="s" tag="h3" isDecoration={false}>
                          Table header
                        </Title>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ColorInput
                        tag="color"
                        label="Background color"
                        name="tableBackgroundColor"
                        value={userStyles.table.header.backgroundColor}
                        onChange={(value) =>
                          setUserStyles((prev) => ({
                            ...prev,
                            table: {
                              ...prev.table,
                              header: {
                                ...prev.table.header,
                                backgroundColor: value,
                              },
                            },
                          }))
                        }
                      />
                      <ColorInput
                        tag="color"
                        label="Text color"
                        name="tableTextColor"
                        value={userStyles.table.header.textColor}
                        onChange={(value) =>
                          setUserStyles((prev) => ({
                            ...prev,
                            table: {
                              ...prev.table,
                              header: {
                                ...prev.table.header,
                                textColor: value,
                              },
                            },
                          }))
                        }
                      />

                      <Input
                        tag="input"
                        type="checkbox"
                        label="Bold text"
                        name="tableBoldText"
                        checked={userStyles.table.header.isBold}
                        onChange={({ target: { checked } }) =>
                          setUserStyles((prev) => ({
                            ...prev,
                            table: {
                              ...prev.table,
                              header: {
                                ...prev.table.header,
                                isBold: checked,
                              },
                            },
                          }))
                        }
                      />
                    </AccordionItemPanel>
                  </section>
                </AccordionItem>
              )}

              {type === 'cards' && (
                <>
                  <AccordionItem>
                    <section>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <Title tag="h3" isDecoration={false} fontSize="s">
                            Cards layout
                          </Title>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div>
                          <Input
                            isRequired
                            tag="input"
                            type="number"
                            label="Number of columns"
                            name="numberColumns"
                            max={4}
                            min={1}
                            value={userStyles.cards?.columns || 3}
                            onChange={({ target: { value } }) =>
                              setUserStyles((prev) => ({
                                ...prev,
                                cards: {
                                  ...prev.cards,
                                  columns: value,
                                },
                              }))
                            }
                          />
                        </div>
                      </AccordionItemPanel>
                    </section>
                  </AccordionItem>
                </>
              )}

              {type === 'list' || type === 'cards' ? (
                <AccordionItem>
                  <section>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Title tag="h3" isDecoration={false} fontSize="s">
                          Single element
                        </Title>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      {type === 'cards' && (
                        <div>
                          <Input
                            name="selectDirection"
                            tag="select"
                            label="Direction"
                            value={userStyles.singleElement.direction}
                            onChange={({ target: { value } }) =>
                              setUserStyles((prev) => ({
                                ...prev,
                                singleElement: {
                                  ...prev.singleElement,
                                  direction: value,
                                },
                              }))
                            }
                          >
                            <option value="column">Column</option>
                            <option value="row">Row</option>
                          </Input>
                        </div>
                      )}
                      <div>
                        <Input
                          name="selectJustifyContent"
                          tag="select"
                          label="Content alignment"
                          value={userStyles.singleElement.justifyContent}
                          onChange={({ target: { value } }) =>
                            setUserStyles((prev) => ({
                              ...prev,
                              singleElement: {
                                ...prev.singleElement,
                                justifyContent: value,
                              },
                            }))
                          }
                        >
                          <option value="flex-start">Start</option>
                          <option value="center">Center</option>
                          <option value="flex-end">End</option>
                          <option value="space-between">Space between</option>
                        </Input>
                      </div>
                      <div>
                        <Input
                          name="selectTextAlign"
                          tag="select"
                          label="Text align"
                          value={userStyles.singleElement.textAlign}
                          onChange={({ target: { value } }) =>
                            setUserStyles((prev) => ({
                              ...prev,
                              singleElement: {
                                ...prev.singleElement,
                                textAlign: value,
                              },
                            }))
                          }
                        >
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                          <option value="center">Center</option>
                        </Input>
                      </div>
                      <div>
                        <GroupOptions
                          name="Shadow"
                          items={[
                            {
                              id: 1,
                              label: 'X',
                              type: 'number',
                              min: -10,
                              max: 10,
                              value: userStyles.singleElement.boxShadow.x,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    boxShadow: {
                                      ...prev.singleElement.boxShadow,
                                      x: Number(value),
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 2,
                              label: 'Y',
                              type: 'number',
                              min: -10,
                              max: 10,
                              value: userStyles.singleElement.boxShadow.y,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    boxShadow: {
                                      ...prev.singleElement.boxShadow,
                                      y: Number(value),
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 3,
                              label: 'Blur',
                              type: 'number',
                              min: 0,
                              max: 25,
                              value: userStyles.singleElement.boxShadow.blur,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    boxShadow: {
                                      ...prev.singleElement.boxShadow,
                                      blur: Number(value),
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 4,
                              label: 'Color',
                              type: 'color',
                              value: rgbToHex(
                                userStyles.singleElement.boxShadow.color
                              ),
                              onChange: (value) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    boxShadow: {
                                      ...prev.singleElement.boxShadow,
                                      color: value,
                                    },
                                  },
                                }));
                              },
                            },
                          ]}
                        />
                      </div>

                      <div>
                        <GroupOptions
                          name="Border radius"
                          items={[
                            {
                              id: 1,
                              label: 'Top left',
                              type: 'number',
                              min: 0,
                              max: 50,
                              value:
                                userStyles.singleElement.borderRadius.topLeft,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    borderRadius: {
                                      ...prev.singleElement.borderRadius,
                                      topLeft: value,
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 2,
                              label: 'Top right',
                              type: 'number',
                              min: 0,
                              max: 50,
                              value:
                                userStyles.singleElement.borderRadius.topRight,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    borderRadius: {
                                      ...prev.singleElement.borderRadius,
                                      topRight: value,
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 3,
                              label: 'Bottom left',
                              type: 'number',
                              min: 0,
                              max: 50,
                              value:
                                userStyles.singleElement.borderRadius
                                  .bottomLeft,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    borderRadius: {
                                      ...prev.singleElement.borderRadius,
                                      bottomLeft: value,
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 4,
                              label: 'Bottom right',
                              type: 'number',
                              value:
                                userStyles.singleElement.borderRadius
                                  .bottomRight,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    borderRadius: {
                                      ...prev.singleElement.borderRadius,
                                      bottomRight: value,
                                    },
                                  },
                                }));
                              },
                            },
                          ]}
                        />
                      </div>
                      <div>
                        <GroupOptions
                          name="Border"
                          items={[
                            {
                              id: 1,
                              label: 'Top',
                              type: 'number',
                              min: 0,
                              max: 5,
                              value: userStyles.singleElement.border.top,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    border: {
                                      ...prev.singleElement.border,
                                      top: Number(value),
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 2,
                              label: 'Bottom',
                              type: 'number',
                              min: 0,
                              max: 5,
                              value: userStyles.singleElement.border.bottom,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    border: {
                                      ...prev.singleElement.border,
                                      bottom: Number(value),
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 3,
                              label: 'Left',
                              type: 'number',
                              min: 0,
                              max: 5,
                              value: userStyles.singleElement.border.left,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    border: {
                                      ...prev.singleElement.border,
                                      left: Number(value),
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              label: 'Right',
                              type: 'number',
                              min: 0,
                              max: 5,
                              value: userStyles.singleElement.border.right,
                              onChange: ({ target: { value } }) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    border: {
                                      ...prev.singleElement.border,
                                      right: value,
                                    },
                                  },
                                }));
                              },
                            },
                            {
                              id: 4,
                              label: 'Color',
                              type: 'color',
                              value: userStyles.singleElement.border.color,
                              onChange: (value) => {
                                setUserStyles((prev) => ({
                                  ...prev,
                                  singleElement: {
                                    ...prev.singleElement,
                                    border: {
                                      ...prev.singleElement.border,
                                      color: value,
                                    },
                                  },
                                }));
                              },
                            },
                          ]}
                        />
                      </div>
                    </AccordionItemPanel>
                  </section>
                </AccordionItem>
              ) : null}
              <AccordionItem>
                <section>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Title fontSize="s" tag="h3" isDecoration={false}>
                        Buttons
                      </Title>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ColorInput
                      tag="color"
                      label="Background color"
                      name="buttonBackgroundColor"
                      value={userStyles.button.backgroundColor}
                      onChange={(value) =>
                        setUserStyles((prev) => ({
                          ...prev,
                          button: {
                            ...prev.button,
                            backgroundColor: value,
                          },
                        }))
                      }
                    />
                    <ColorInput
                      label="Text color"
                      name="buttonTextColor"
                      value={userStyles.button.textColor}
                      onChange={(value) =>
                        setUserStyles((prev) => ({
                          ...prev,
                          button: {
                            ...prev.button,
                            textColor: value,
                          },
                        }))
                      }
                    />
                    <Input
                      tag="input"
                      type="checkbox"
                      label="Bold text"
                      name="boldText"
                      checked={userStyles.button.isBold}
                      onChange={({ target: { checked } }) =>
                        setUserStyles((prev) => ({
                          ...prev,
                          button: {
                            ...prev.button,
                            isBold: checked,
                          },
                        }))
                      }
                    />
                    <GroupOptions
                      name="Border radius"
                      items={[
                        {
                          id: 1,
                          name: 'Top left',
                          label: 'Top left',
                          type: 'number',
                          min: 0,
                          max: 50,
                          value: userStyles.button.borderRadius.topLeft,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              button: {
                                ...prev.button,
                                borderRadius: {
                                  ...prev.button.borderRadius,
                                  topLeft: value,
                                },
                              },
                            }));
                          },
                        },
                        {
                          id: 2,
                          name: 'Top right',
                          label: 'Top right',
                          type: 'number',
                          min: 0,
                          max: 50,
                          value: userStyles.button.borderRadius.topRight,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              button: {
                                ...prev.button,
                                borderRadius: {
                                  ...prev.button.borderRadius,
                                  topRight: value,
                                },
                              },
                            }));
                          },
                        },
                        {
                          id: 3,
                          name: 'Bottom left',
                          label: 'Bottom left',
                          type: 'number',
                          min: 0,
                          max: 50,
                          value: userStyles.button.borderRadius.bottomLeft,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              button: {
                                ...prev.button,
                                borderRadius: {
                                  ...prev.button.borderRadius,
                                  bottomLeft: value,
                                },
                              },
                            }));
                          },
                        },
                        {
                          id: 4,
                          name: 'Bottom right',
                          label: 'Bottom right',
                          type: 'number',
                          value: userStyles.button.borderRadius.bottomRight,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              button: {
                                ...prev.button,
                                borderRadius: {
                                  ...prev.button.borderRadius,
                                  bottomRight: value,
                                },
                              },
                            }));
                          },
                        },
                      ]}
                    />
                  </AccordionItemPanel>
                </section>
              </AccordionItem>
              <AccordionItem>
                <section>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Title fontSize="s" tag="h3" isDecoration={false}>
                        Images
                      </Title>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <GroupOptions
                      name="Border radius"
                      items={[
                        {
                          id: 1,
                          name: 'Top left',
                          label: 'Top left',
                          type: 'number',
                          min: 0,
                          max: 50,
                          value: userStyles.image.borderRadius.topLeft,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              image: {
                                ...prev.image,
                                borderRadius: {
                                  ...prev.image.borderRadius,
                                  topLeft: value,
                                },
                              },
                            }));
                          },
                        },
                        {
                          id: 2,
                          name: 'Top right',
                          label: 'Top right',
                          type: 'number',
                          min: 0,
                          max: 50,
                          value: userStyles.image.borderRadius.topRight,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              image: {
                                ...prev.image,
                                borderRadius: {
                                  ...prev.image.borderRadius,
                                  topRight: value,
                                },
                              },
                            }));
                          },
                        },
                        {
                          id: 3,
                          name: 'Bottom left',
                          label: 'Bottom left',
                          type: 'number',
                          min: 0,
                          max: 50,
                          value: userStyles.image.borderRadius.bottomLeft,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              image: {
                                ...prev.image,
                                borderRadius: {
                                  ...prev.image.borderRadius,
                                  bottomLeft: value,
                                },
                              },
                            }));
                          },
                        },
                        {
                          id: 4,
                          name: 'Bottom right',
                          label: 'Bottom right',
                          type: 'number',
                          value: userStyles.image.borderRadius.bottomRight,
                          onChange: ({ target: { value } }) => {
                            setUserStyles((prev) => ({
                              ...prev,
                              image: {
                                ...prev.image,
                                borderRadius: {
                                  ...prev.image.borderRadius,
                                  bottomRight: value,
                                },
                              },
                            }));
                          },
                        },
                      ]}
                    />
                    {type === 'list' || type === 'cards' || type === 'table' ? (
                      <GroupOptions
                        name="Shadow"
                        items={[
                          {
                            id: 1,
                            label: 'x',
                            name: 'imageShadowX',
                            type: 'number',
                            value: userStyles.image.boxShadow.x,
                            onChange: ({ target: { value } }) => {
                              setUserStyles((prev) => ({
                                ...prev,
                                image: {
                                  ...prev.image,
                                  boxShadow: {
                                    ...prev.image.boxShadow,
                                    x: Number(value),
                                  },
                                },
                              }));
                            },
                          },
                          {
                            id: 2,
                            label: 'y',
                            name: 'imageShadowY',
                            type: 'number',
                            value: userStyles.image.boxShadow.y,
                            onChange: ({ target: { value } }) => {
                              setUserStyles((prev) => ({
                                ...prev,
                                image: {
                                  ...prev.image,
                                  boxShadow: {
                                    ...prev.image.boxShadow,
                                    y: Number(value),
                                  },
                                },
                              }));
                            },
                          },
                          {
                            id: 3,
                            label: 'blur',
                            name: 'imageShadowBlur',
                            type: 'number',
                            value: userStyles.image.boxShadow.blur,
                            onChange: ({ target: { value } }) => {
                              setUserStyles((prev) => ({
                                ...prev,
                                image: {
                                  ...prev.image,
                                  boxShadow: {
                                    ...prev.image.boxShadow,
                                    blur: Number(value),
                                  },
                                },
                              }));
                            },
                          },
                          {
                            type: 'color',
                            label: 'color',
                            name: 'imageShadowColor',
                            value: userStyles.image.boxShadow.color,
                            onChange: (value) => {
                              setUserStyles((prev) => ({
                                ...prev,
                                image: {
                                  ...prev.image,
                                  boxShadow: {
                                    ...prev.image.boxShadow,
                                    color: value,
                                  },
                                },
                              }));
                            },
                          },
                        ]}
                      />
                    ) : null}
                  </AccordionItemPanel>
                </section>
              </AccordionItem>
              <div className="CustomizationForm__endButtons">
                <Button isText padding="s" to="/">
                  Cancel
                </Button>
                <Button type="submit">{isEdit ? 'Update' : 'Create'}</Button>
              </div>
            </form>
            <div className="CustomizationForm__preview">
              <embedtables-element
                type={type}
                projectdata={JSON.stringify(projectFields.fields)}
                userstyles={JSON.stringify(userStyles)}
              />
            </div>
          </div>
        </Accordion>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}
/* eslint-disable */
CustomizationForm.propTypes = {
  baseId: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  apiKey: PropTypes.string,
  currentIntegrationPassed: PropTypes.string,
  userStylesPassed: PropTypes.object,
  projectTypePassed: PropTypes.string,
  projectNamePassed: PropTypes.string,
  columnsToHidePassed: PropTypes.array,
  getCustomizationData: PropTypes.func.isRequired,
  passCustomizationData: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};
CustomizationForm.defaultProps = {
  apiKey: '',
  currentIntegrationPassed: '',
  userStylesPassed: {},
  columnsToHidePassed: [],
  projectTypePassed: '',
  projectNamePassed: '',
};
