/* eslint-disable */
export const getGreeting = () => {
  const date = new Date().getHours();
  if (date < 12) {
    return 'Good morning';
  }
  if (date < 18) {
    return 'Good afternoon';
  }
  return 'Good evening';
};
export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;

    // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }

  return `${+r},${+g},${+b}`;
};
export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export const rgbToHex = (rgbClr) => {
  var rgbClr = rgbClr.split(',');
  var r = rgbClr[0];
  var g = rgbClr[1];
  var b = rgbClr[2];
  return `#${((r << 16) | (g << 8) | b).toString(16).toUpperCase()}`;
};
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
