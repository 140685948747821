import axios from 'axios';

export const createProjectItem = (
  apiKey,
  baseId,
  tableName,
  projectName,
  integrationName,
  userStyles,
  type,
  columnsToHide,
  userId
) =>
  axios
    .post(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/`, {
      apiKey,
      baseId,
      tableName,
      projectName,
      integrationName,
      userStyles,
      type,
      columnsToHide,
      userId,
    })

    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));

export const getProjectsByUserId = (userId) =>
  axios
    .get(
      `${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/user/netlify/${userId}`
    )
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));
export const getUserByNetlifyId = (netlifyId) =>
  axios
    .get(
      `${process.env.REACT_APP_LAMBDA_ENDPOINT}/users/netlify-id/${netlifyId}`
    )
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));
export const getUserSubscriptionInfo = (userId) =>
  axios
    .get(
      `${process.env.REACT_APP_LAMBDA_ENDPOINT}/subscriptions/user-id/${userId}`
    )
    .then((res) => res.data[0].data)
    .catch((error) => console.error('Error: ', error.message));
export const getProjectsById = (id) =>
  axios
    .get(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/${id}`)
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));

export const createStripeCheckoutSession = (email, id) => {
  return axios
    .post(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/checkout`, {
      email,
      netlifyId: id,
    })
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));
};
export const getGridsBySheetId = (sheetId) => {
  return axios
    .get(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/google-sheets/${sheetId}`)
    .then((res) => res.data.data)
    .catch((error) => console.error('Error: ', error.message));
};
export const getFieldsFromGoogleSheets = (baseId, sheetId = 0) => {
  return axios
    .get(
      `${process.env.REACT_APP_LAMBDA_ENDPOINT}/google-sheets/${baseId}/${sheetId}`
    )
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));
};
export const getFieldsFromAirtable = (apiKey, baseId, sheetId = 0) => {
  return axios
    .get(
      `${process.env.REACT_APP_LAMBDA_ENDPOINT}/airtable/${apiKey}/${baseId}/${sheetId}`
    )
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));
};
export const getDataByProjectId = (projectId) => {
  return axios
    .get(`${process.env.REACT_APP_LAMBDA_ENDPOINT}/projects/data/${projectId}`)
    .then((res) => res.data)
    .catch((error) => console.error('Error: ', error.message));
};
