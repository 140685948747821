import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import AppContext from 'context';

import { Link, useHistory } from 'react-router-dom';

import airtableLogo from 'assets/images/airtable-logo.png';
import sheetsLogo from 'assets/images/sheets-logo.png';

import authRequest from 'api/authRequest';

import list from 'assets/types-icons/list.svg';
import table from 'assets/types-icons/table.svg';
import cards from 'assets/types-icons/cards.svg';
import inline from 'assets/types-icons/inline.svg';

import SVGIcon from 'components/SVGIcon';

import { useIdentityContext } from 'react-netlify-identity-widget';

import './style.scss';
import Title from 'components/Title';
import Header from 'components/Header';
import InfoBox from 'components/InfoBox';

const Dashboard = () => {
  const history = useHistory();

  const [usersProjects, setUsersProjects] = useState(null);
  const { loaded, userDataValue } = React.useContext(AppContext);
  const [userData] = userDataValue;

  const identity = useIdentityContext();
  const API = authRequest(identity);

  useEffect(() => {
    const fetchData = async () => {
      const data = await API.getProjectsByUserId(userData.id);
      if (data) {
        setUsersProjects(data.reverse());
      }
    };
    if (loaded && userData?.id) {
      fetchData();
    }
  }, [loaded, userData]);

  const deleteProject = async (projectId) => {
    setUsersProjects((prev) =>
      prev.filter((el) => el.ref['@ref'].id !== projectId)
    );
    API.deleteProjectById(projectId);
  };

  const editProject = async (projectId) => {
    history.push(`/edit/${projectId}`);
    // setUsersProjects((prev) =>
    //   prev.filter((el) => el.ref['@ref'].id !== projectId)
    // );
    // API.deleteProjectById(projectId);
  };

  const typeIcon = (type) => {
    let el = null;
    switch (type) {
      case 'list':
        el = <img src={list} alt="" />;
        break;
      case 'table':
        el = <img src={table} alt="" />;
        break;
      case 'cards':
        el = <img src={cards} alt="" />;
        break;
      case 'inline':
        el = <img src={inline} alt="" />;
        break;
      default:
        el = <img src={cards} alt="" />;
        break;
    }
    return el;
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.analytics.page('Dashboard');
    }
  }, []);

  return (
    <main className="Dashboard">
      {loaded ? (
        <div>
          <Header isCenter>
            <div className="Dashboard__greetings">
              {/* <Title
                tag="span"
                fontSize="l"
                isMargin
                isWhite
                isDecoration={false}
              >
                You&apos;ve created {usersProjects && usersProjects.length}{' '}
                projects already!
              </Title> */}
              <div className="Dashboard__cta">
                <Button
                  to="/create"
                  onClick={() => {
                    if (process.env.NODE_ENV !== 'development') {
                      window.analytics.track('Create new project CTA');
                    }
                  }}
                >
                  Create new project
                </Button>
              </div>
            </div>
          </Header>
          <div className="Dashboard__content">
            <div className="Dashboard__tips">
              <Title fontSize="s" tag="h3" isDecoration={false}>
                Quick tips
              </Title>
              <div className="Dashboard__tips-list">
                <div className="Dashboard__tip">
                  <Title fontSize="xs" tag="span" isDecoration={false}>
                    Using images
                  </Title>
                  <p>
                    To insert images create a new column named
                    &ldquo;Images_url&rdquo; and put there absolute links to
                    photos in any of deserved formats (png, jpg, gif, webp).
                  </p>
                </div>
                <div className="Dashboard__tip">
                  <Title fontSize="xs" tag="span" isDecoration={false}>
                    Using buttons (links)
                  </Title>
                  <p>
                    To create a button you need to create 2 columns named
                    “Button_text” and “Button_url” and as names suggest one is
                    for text displayed on a button and the other for the link
                    that button directs to.
                  </p>
                </div>
              </div>
            </div>
            <Title tag="h2" fontSize="m" isDecoration>
              All projects{' '}
              <span className="Dashboard__projects-num">
                {usersProjects && usersProjects.length}
              </span>
            </Title>
            {usersProjects && usersProjects.length > 0 ? (
              <div className="Dashboard__projects">
                {usersProjects.map(
                  ({
                    ref,
                    ts,
                    data: { projectName, integrationName, type },
                  }) => (
                    <div>
                      <div
                        className="Dashboard__project-row"
                        key={ref['@ref'].id}
                      >
                        <div className="Dashboard__icons">
                          <div className="Dashboard__project-row__logo">
                            {integrationName === 'airtable' ? (
                              <img
                                src={airtableLogo}
                                alt="Airtable integration"
                              />
                            ) : (
                              <img
                                src={sheetsLogo}
                                alt="Google Sheets integration"
                              />
                            )}
                          </div>
                          <div>{typeIcon(type)}</div>
                        </div>
                        <Link to={`/project/${ref['@ref'].id}`}>
                          <h3>
                            {projectName}{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className="Dashboard__arrowIcon"
                                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z"
                              />
                            </svg>{' '}
                          </h3>
                        </Link>
                        <div>
                          <span className="Dashboard__label">Last update</span>
                          <span>
                            {new Date(ts / 1000).toLocaleDateString()}
                          </span>
                        </div>
                        <div>
                          <span className="Dashboard__label">Project ID</span>
                          <span>{ref['@ref'].id}</span>
                        </div>
                        <Button
                          isText
                          padding="s"
                          onClick={() => {
                            editProject(ref['@ref'].id);
                          }}
                        >
                          <>
                            Edit
                            <SVGIcon size={16} name="edit" fill="#ee6c4d" />
                          </>
                        </Button>
                        <Button
                          isText
                          padding="s"
                          onClick={() => {
                            deleteProject(ref['@ref'].id);
                          }}
                        >
                          Delete
                          <SVGIcon size={16} name="delete" fill="#ee6c4d" />
                        </Button>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <InfoBox title="Nothing's there.">
                <span>Create your very first project!</span>
              </InfoBox>
            )}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </main>
  );
};
export default Dashboard;
