import React from 'react';
import './style.scss';

import PropTypes from 'prop-types';

export default function Header({ isCenter, children }) {
  let positionClass = '';
  if (isCenter) {
    positionClass += 'Header--center';
  }

  return <header className={`Header ${positionClass}`}>{children}</header>;
}

Header.propTypes = {
  isCenter: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Header.defaultProps = {
  isCenter: false,
};
