// @flow

import * as React from 'react';
import type { Node } from 'react';

import Title from 'components/Title';

import './style.scss';

type Props = {
  children: Node,
  title: string,
};

export default function InfoBox(props: Props): Node {
  const { children, title } = props;
  return (
    <div className="InfoBox">
      <div className="InfoBox__header">
        <Title tag="span" fontSize="s" isDecoration={false}>
          {title}
        </Title>
      </div>
      <div className="InfoBox__content">{children}</div>
    </div>
  );
}
