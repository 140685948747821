import React, { useEffect, useState } from 'react';
import IdentityModal, {
  useIdentityContext,
} from 'react-netlify-identity-widget';
import { getUserByNetlifyId, getUserSubscriptionInfo } from 'api';
import PropTypes from 'prop-types';
import AppContext from '.';

const ContextProvider = ({ children }) => {
  const identity = useIdentityContext();
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loginDialog, setLoginDialog] = useState(false);

  useEffect(() => {
    async function getUserDataByNetlifyId(netlifyId) {
      const data = await getUserByNetlifyId(netlifyId);
      return data;
    }
    async function getSubscriptionInfo(userId) {
      const data = await getUserSubscriptionInfo(userId);
      return data;
    }
    async function main() {
      setLoaded(false);
      const dataUser = await getUserDataByNetlifyId(identity.user.id);
      const dataSubs = await getSubscriptionInfo(dataUser.id);
      setUserData(dataUser);
      setSubscriptionData(dataSubs);
      setLoaded(true);
    }

    if (identity.user) {
      main();
    }
  }, [identity.user]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && loaded) {
      window.analytics.identify(userData.id, {
        name: userData.fullName,
        email: userData.email,
      });
    }
  }, [loaded, userData]);

  return (
    <AppContext.Provider
      value={{
        loaded,
        userDataValue: [userData, setUserData],
        loginDialogValue: [loginDialog, setLoginDialog],
        subscriptionDataValue: [subscriptionData, setSubscriptionData],
      }}
    >
      {children}
      <IdentityModal
        showDialog={loginDialog}
        onCloseDialog={() => setLoginDialog(false)}
      />
    </AppContext.Provider>
  );
};
ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ContextProvider;
