import React, { useState } from 'react';
import templateOptions from 'constants/templateOptions';
import PropTypes from 'prop-types';
import './style.scss';
import Button from 'components/Button';

export default function TemplatesGallery({
  getSelectedTemplate,
  next,
  isSelectable,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const submitForm = () => {
    next();
    getSelectedTemplate(selectedTemplate);
  };

  return (
    <form className="" onSubmit={submitForm}>
      <div className="TemplatesGallery__elements">
        {templateOptions.map((i) => (
          <label className="TemplatesGallery__radioSelect">
            <input
              type="radio"
              name={i.id}
              id={i.id}
              checked={selectedTemplate === i.slug}
              onChange={() => setSelectedTemplate(i.slug)}
            />
            <div className="TemplatesGallery__elementWrapper">
              <div key={i.id} className="TemplatesGallery__element">
                <div className="TemplatesGallery__preview">
                  <div className="TemplatesGallery__inner">
                    <img alt={i.slug} src={`/templates-images/${i.slug}.png`} />
                  </div>
                </div>
                <div className="TemplatesGallery__templateInfo">
                  <span className="TemplatesGallery__templateName">
                    {i.label}
                  </span>
                </div>
              </div>
            </div>
          </label>
        ))}
      </div>
      {isSelectable && (
        <div className="TemplatesGallery__endButtons">
          <Button isText padding="s" to="/">
            Cancel
          </Button>
          <Button type="submit" disabled={!selectedTemplate}>
            Next step
          </Button>
        </div>
      )}
    </form>
  );
}
TemplatesGallery.propTypes = {
  next: PropTypes.func.isRequired,
  getSelectedTemplate: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
};
TemplatesGallery.defaultProps = {
  isSelectable: false,
};
