/* eslint-disable */

import React from 'react';

import PropTypes from 'prop-types';

import { BlockPicker } from 'react-color';

import './style.scss';
// import ExternalLinkIcon from 'assets/ExternalLinkIcon';
import SVGIcon from 'components/SVGIcon';

const Input = ({
  tag: Tag,
  name,
  label,
  placeholder,
  explainer,
  isRequired,
  maxLength,
  type,
  children,
  value,
  onChange,
  ...props
}) => {
  let wrapperClass = 'Input__wrapper';

  if (type === 'checkbox') wrapperClass = 'Input__wrapper--inline';

  return (
    <>
      <label htmlFor={name} className={wrapperClass}>
        <span className="Input__label">
          {label}{' '}
          {explainer && (
            <button
              type="button"
              aria-label="Show explanation"
              className="button-reset"
            >
              <SVGIcon fill="#000" size={14} name="more-info" />
            </button>
          )}
        </span>
        {Tag === 'color' ? (
          <BlockPicker color={value} onChangeComplete={onChange} />
        ) : Tag === 'select' ? (
          <div className="Input__wrapperSelect">
            <Tag
              type={type}
              name={name}
              id={name}
              className={Tag}
              required={isRequired}
              maxLength={maxLength}
              value={value}
              onChange={onChange}
              {...props}
            >
              {children}
            </Tag>
          </div>
        ) : (
          <Tag
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            className={type === 'color' ? `${Tag}--color` : Tag}
            required={isRequired}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            {...props}
          />
        )}
      </label>
    </>
  );
};

Input.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  explainer: PropTypes.string,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  tag: 'input',
  type: 'text',
  explainer: '',
  placeholder: '',
  children: null,
  maxLength: 50,
  isRequired: false,
};

export default Input;
