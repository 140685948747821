import './style.scss';
import { createProjectItem } from 'api';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useIdentityContext } from 'react-netlify-identity-widget';

import { useHistory, useParams } from 'react-router-dom';
import { Wizard, Steps, Step } from 'react-albus';
// import { Line } from 'rc-progress';
import AppContext from 'context';
import authRequest from 'api/authRequest';

import ConfigurationForm from 'components/ConfigurationForm';
import LoadingSpinner from 'components/LoadingSpinner';
import Title from 'components/Title';
import Button from 'components/Button';
import CustomizationForm from 'components/CustomizationForm';
import InfoBox from 'components/InfoBox';

import TemplatesGallery from 'components/TemplatesGallery';
import templateOptions from 'constants/templateOptions';
import calendarOne from './oldTemplatesStyles/calendarOne';
import storeBookStore from './oldTemplatesStyles/storeBookStore';
import minimal from './oldTemplatesStyles/minimal';
import listBasic from './oldTemplatesStyles/listBasic';

import templatesStyles from './templates/styles';
import templatesTypes from './templates/types';
import templatesColumnsToHide from './templates/tohide';

export default function CreateProject() {
  const { loaded, userDataValue } = useContext(AppContext);
  const [userData] = userDataValue;
  const { projectId } = useParams();
  const identity = useIdentityContext();
  const API = authRequest(identity);

  const [editLoaded, setEditLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // config
  const [baseId, setBaseId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [tableName, setTableName] = useState('');
  const [currentIntegration, setCurrentIntegration] = useState(null);

  // basic info

  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState('');
  const [columnsToHide, setColumnsToHide] = useState([]);
  const [templateSlug, setTemplateSlug] = useState('');
  const [templateInfoObject, setTemplateInfoObject] = useState(null);

  const [userStyles, setUserStyles] = useState(null);

  const history = useHistory();

  const processInput = useCallback(async () => {
    let response = null;
    if (userData) {
      if (!projectId) {
        response = await createProjectItem(
          apiKey,
          baseId,
          tableName,
          projectName,
          currentIntegration,
          userStyles,
          projectType,
          columnsToHide,
          userData.id
        );
        if (process.env.NODE_ENV !== 'development') {
          window.analytics.track('Created project', {
            integration: currentIntegration,
            viewType: projectType,
            howManyColumnsHide: columnsToHide ? columnsToHide.length : 0,
          });
        }
      } else {
        const data = {
          apiKey,
          baseId,
          tableName,
          projectName,
          integrationName: currentIntegration,
          userStyles,
          columnsToHide,
          type: projectType,
          userId: userData.id,
        };
        if (process.env.NODE_ENV !== 'development') {
          window.analytics.track('Edited project', {
            integration: currentIntegration,
            viewType: projectType,
            howManyColumnsHide: columnsToHide ? columnsToHide.length : 0,
          });
        }
        response = await API.editProjectById(projectId, data);
      }
      if (response) {
        history.push(`/project/${response.ref['@ref'].id}`);
      }
    }
  }, [
    apiKey,
    baseId,
    tableName,
    projectName,
    currentIntegration,
    userData,
    userStyles,
    projectType,
    columnsToHide,
    history,
  ]);

  function setConfigData(data) {
    setBaseId(data.baseId);
    setApiKey(data.apiKey);
    setCurrentIntegration(data.currentIntegration || data.integrationName);
    setTableName(data.tableName);
  }

  // useDidMountEffect(() => {
  //   if (userStyles && projectName && projectType) {
  //     processInput();
  //   }
  // }, [userStyles, projectName, projectType]);

  function setDataOnSubmit(styles, name, type, tohide) {
    setUserStyles(styles);
    setProjectName(name);
    setProjectType(type);
    setColumnsToHide(tohide);
  }

  useEffect(() => {
    if (templateSlug) {
      setUserStyles(templatesStyles[templateSlug]);
      setProjectType(templatesTypes[templateSlug]);
      setColumnsToHide(templatesColumnsToHide[templateSlug] || []);
      setTemplateInfoObject(
        templateOptions.find((el) => el.slug === templateSlug)
      );
    }
  }, [templateSlug]);

  useEffect(() => {
    async function getProjectData() {
      const data = await API.getProjectById(projectId);
      setConfigData(data);
      let processedStyles = null;
      if (data.templateSlug) {
        switch (data.templateSlug) {
          case 'calendar-basic':
            processedStyles = calendarOne(
              data.primaryColor,
              data.secondaryColor
            );
            break;
          case 'store-book-store':
            processedStyles = storeBookStore(
              data.primaryColor,
              data.secondaryColor
            );
            break;
          case 'list-basic-2':
            processedStyles = minimal(data.primaryColor, data.secondaryColor);
            break;
          case 'list-basic':
            processedStyles = listBasic(data.primaryColor, data.secondaryColor);
            break;
          default:
            processedStyles = null;
            break;
        }
      }
      setUserStyles(data.userStyles || processedStyles);
      setProjectType(data.type);
      setProjectName(data.projectName);
      setColumnsToHide(data.columnsToHide);
      setEditLoaded(true);
    }
    if (projectId) {
      setIsEdit(true);
      getProjectData();
    }
  }, [projectId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.analytics.page('Create new project');
    }
  }, []);

  const PageContent = (
    <main className="CreateProject">
      <Wizard
        render={({ step }) => (
          <main>
            <Steps key={step.id} step={step}>
              <Step
                id="templates"
                render={({ next }) => {
                  if (isEdit) {
                    next();
                    return <></>;
                  }
                  return (
                    <div className="CreateProject__step">
                      <Title tag="h2" fontSize="m">
                        Select template
                      </Title>
                      <TemplatesGallery
                        next={next}
                        isSelectable
                        getSelectedTemplate={(slug) => setTemplateSlug(slug)}
                      />
                    </div>
                  );
                }}
              />

              <Step
                id="configForm"
                render={({ next }) => (
                  <div className="CreateProject__step CreateProject__config">
                    <div>
                      <Title tag="h2" fontSize="m">
                        Connect to your spreadsheet
                      </Title>
                      {templateInfoObject && (
                        <InfoBox title="Example spreadsheet">
                          <p>
                            You can kickstart your project with an example
                            Google Sheet dedicated to the{' '}
                            {templateInfoObject.label} template.
                          </p>
                          <Button
                            padding="s"
                            secondary
                            isTargetBlank
                            iconFill="secondary"
                            iconFillHover="primary"
                            href={templateInfoObject.sheetUrl}
                          >
                            Google Sheet
                          </Button>
                        </InfoBox>
                      )}
                      <ConfigurationForm
                        next={next}
                        currentIntegrationPassed={currentIntegration}
                        baseIdPassed={baseId}
                        tableNamePassed={tableName}
                        apiKeyPassed={apiKey}
                        getConfigFormData={(data) => setConfigData(data)}
                      />
                    </div>
                    <div className="CreateProject__help">
                      <Title tag="h3" fontSize="s" isDecoration={false}>
                        Need some help?
                      </Title>
                      <p>
                        Check out EmbedTables tutorials to get on track{' '}
                        <a
                          className="primary-link"
                          href="https://embedtables.com/tutorials"
                        >
                          View tutorials
                        </a>
                      </p>

                      <p>
                        You can shoot me an email at{' '}
                        <a
                          href="mailto:dominik@embedtables.com?subject=Help with configuration needed!"
                          className="primary-link"
                        >
                          dominik@embedtables.com
                        </a>{' '}
                        and I&apos;ll help you with all the configuration
                        details as fast as it is possible (remeber I&apos;m in
                        GMT+1){' '}
                        <span role="img" aria-hidden="true">
                          😃
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              />

              <Step
                id="customization"
                render={({ next, previous }) => (
                  <div>
                    <CustomizationForm
                      currentIntegrationPassed={currentIntegration}
                      baseId={baseId}
                      tableName={tableName}
                      userStylesPassed={userStyles}
                      projectTypePassed={projectType}
                      projectNamePassed={projectName}
                      columnsToHidePassed={columnsToHide}
                      apiKey={apiKey}
                      isEdit={isEdit}
                      getCustomizationData={(styles, name, type, tohide) => {
                        setDataOnSubmit(styles, name, type, tohide);
                      }}
                      passCustomizationData={() => processInput()}
                      next={next}
                      previous={previous}
                    />
                  </div>
                )}
              />
            </Steps>
          </main>
        )}
      />
    </main>
  );

  let PageElement = null;

  if (projectId) {
    if (editLoaded) {
      PageElement = PageContent;
    } else {
      PageElement = <LoadingSpinner />;
    }
  }

  return (
    <>
      {loaded ? (
        <>{projectId ? PageElement : PageContent}</>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}
