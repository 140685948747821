import React from 'react';
import './style.scss';

import PropTypes from 'prop-types';

const Radio = ({ id, checked, onChange, children }) => (
  <label className="FormRadio" htmlFor={id}>
    <input id={id} type="radio" checked={checked} onChange={onChange} />
    <div className="FormRadio__button" />
    {children}
  </label>
);
Radio.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Radio.defaultProps = {
  checked: false,
};
export default Radio;
